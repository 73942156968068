/* Pagination Dots Styling */
.swiper-pagination-bullet {
    background-color: #DADADA; /* Default dot color */
    opacity: 0.5;
    width: 12px;
    height: 12px;
    transition: background-color 0.3s, opacity 0.3s;
}

.swiper-pagination-bullet-active {
    background-color: #00ADB5; /* Active dot color */
    opacity: 1;
}

/* Position Pagination Below the Slider */
.swiper-pagination {
    position: absolute;
    bottom: 0; /* Position below the block */
    text-align: center;
}
